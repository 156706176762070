import React, { Component } from "react";
import { Link } from "react-router-dom";

import api from "../../Environment";

import Slider from "../NewUI/MainSlider";
import HomePageBanner from "../NewUI/homePageBanner";
import ContentLoader from "../Static/contentLoader";

import configuration from "react-global-configuration";

import {
    translate
} from "react-multi-lang";
class Home extends Component {
    state = {
        maindata: null,
        errorHandle: 0,
        loading: true,
        banner: null,
        loadingHomeSecondSection: true, // azz
        loadingSeriesSection: true, // azz
        homeSecondData: null, // azz
        seriesData: null, // azz
    };

    componentDidMount() {
        const inputData = {
          page_type: "HOME",
          id: 1,
          sub_profile_id: 1,
          token: "2y10LVpd5sCzdJwNTYFxmIWF1ewYiBI1ddUjMAD3uWm5kiQnyregSo5u"
        };
        api.postMethod("home_first_section", inputData)
            .then(response => {
                if (response.data.success === true) {
                    // let maindata = response.data.data;
                    let banner = response.data.banner;

                    this.setState({
                        loading: false,
                        maindata: [],
                        banner: banner
                    });
                } else {
                    let errorHandle = 1;
                    this.setState({ errorHandle });
                }
            })
            .catch(function(error) {});
            
            this.loadSeriasData(inputData)
            
    }
    
  async loadSeriasData(inputData) {
        
                // Azzam To get all the movies for the home page
           await api
            .postMethod("home_second_section", inputData)
            .then((response) => {
                if (response.data.success === true) {
                // console.log("Dandash: ", response.data.Sub_Categories)
                this.setState({
                    loadingSeriesSection: false,
                    seriesData: response.data.data,
                    // seriesData: response.data.Sub_Categories,
                });
            } else {
                let errorHandle = 1;
                this.setState({ errorHandle });
            }
            })
               .catch(function (error) { });
         this.loadSecondData(inputData)
        // End
            }

            async loadSecondData(inputData) {
           await api
            .postMethod("home_series_section", inputData)
            .then((response) => {
                if (response.data.success === true) {
                this.setState({
                    loadingHomeSecondSection: false,
                    homeSecondData: response.data.data,
                    homeData: response.data.Sub_Categories,
                });
            } else {
                let errorHandle = 1;
                this.setState({ errorHandle });
            }
            })
               .catch(function (error) { });

            }
    
      

    renderVideoList = (maindata, index) => {
        
        return (
            <React.Fragment key={index}>
                <div className="main-slidersec">
                    <Link
                        to={{
                            pathname: "/view-all",
                            state: {
                                url_type: maindata.url_type,
                                url_type_id: maindata.url_type_id,
                                page_type: "HOME",
                                title: maindata.title
                            }
                        }}
                    >
                        <h3 className="">
                            {maindata.title}
                            <i className="fas fa-angle-right ml-2" />
                        </h3>
                    </Link>
             {/* { maindata.title === "Friends" ? "" : */}
                        <Slider>                        
                            {maindata.data.map(movie => (
                                <Slider.Item
                                    movie={movie}
                                    key={movie.admin_video_id}
                                    count={maindata.data}
                                >
                                    item1
                                </Slider.Item>
                            ))}
                    </Slider>
                    
                    {/* } */}
                </div>
            </React.Fragment>
        );
    };

    renderSeriesList = (maindata, index) => {
        // console.log(maindata)
        return (
            <React.Fragment key={index}>
                <div className="main-slidersec">
                    <Link
                        to={{
                            pathname: "/view-all",
                            state: {
                                url_type: maindata.url_type,
                                url_type_id: maindata.url_type_id,
                                page_type: "HOME",
                                title: maindata.title
                            }
                        }}
                    >
                        <h3 className="">
                            {maindata.title}
                            <i className="fas fa-angle-right ml-2" />
                        </h3>
                    </Link>

                    <Slider>
                        {maindata.data.map(movie => (
                            <Slider.ItemSeries
                                movie={movie}
                                key={movie.admin_video_id}
                                count={maindata.data}
                            >
                                item1
                            </Slider.ItemSeries>
                        ))}
                    </Slider>
                </div>
            </React.Fragment>
        );
    };

    render() {
        const { t } = this.props;

        const { loading, maindata, banner, loadingHomeSecondSection, loadingSeriesSection, homeSecondData, seriesData } = this.state;

        return (
          <div className="main-sec-content">

              {/* nav bar */}

                <nav className="navbar navbar-expand navbar-dark main-nav fixed-top nav_trans"
                    id="header"
                >
                    <Link className="navbar-brand" to="/home">
                        <img
                            src={configuration.get("configData.site_logo")}
                            className="logo-img desktop-logo"
                            alt="logo_img"
                        />
                        <img
                            src={configuration.get("configData.site_icon")}
                            className="logo-img mobile-logo"
                            alt="logo_img"
                        />
              </Link>
              
              <div className="main-nav-btns">
                 <Link
                                                to="/login"
                                               
                                                className="btn btn-danger login_btn_main_nav"
                                            >
                                                <i  />
                                                Login
                                            </Link>
                                            <Link
                                                to="/subscribe-with-Stripe"
                                                className="btn btn-danger login_btn_main_nav"                                             
                                                
                                            >
                                                 {
                                                    <div>                                                      
                                                       
                                                       Subscribe
                                                    </div>
                                                }
                                            </Link>    
              </div>
                </nav>
                <div className="header-height"></div>
            
            {/* nav bar */}
                {loading ? (
                    <ContentLoader />
                ) : (
                  <HomePageBanner banner={banner} />    
                )}
                <div className="main p-40">
                    {/* {renderMyList} */}

                    {loading
                        ? ""
                        : maindata.map((mainDa, index) =>
                              mainDa.data.length === 0
                                  ? ""
                                  : loading
                                  ? t("")
                                  : this.renderVideoList(mainDa, index)
                          )}
                     {loadingSeriesSection
                        ? ""
                        : seriesData.map((mainDa, index) =>                          
                            mainDa.data.length === 0
                            ? ""
                            : loading
                            ? ""
                            : this.renderVideoList(mainDa, index)
                            
                        )}
                    {loadingHomeSecondSection
                        ? ""
                        : homeSecondData.map((mainDa, index) =>                          
                            mainDa.data.length === 0
                            ? ""
                            : loading
                            ? ""
                            : this.renderVideoList(mainDa, index)
                            
                        )}
                    
                   

                    <div className="height-100" />
                </div>
            </div>
        );
    }
}

export default translate(Home);

