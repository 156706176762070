import React from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import SliderContext from "./context";
import Mark from "./Mark";
import "./Item.scss";
import Helper from "../../Helper/helper";

class Item extends Helper {
    state = {
        loadingFirst: true,
        videoDetailsFirst: null,
        redirect: false,
        redirectPPV: false,
        redirectPaymentOption: false,
        playButtonClicked: false
    };

    componentDidMount() {
        this.setState({ playButtonClicked: false });
    }

    handlePlayVideo = async event => {
        event.preventDefault();

        const inputData = {
            admin_video_id: this.props.movie.admin_video_id,
            skip: 0
        };

        await this.onlySingleVideoFirst(inputData);

        this.redirectStatus(this.state.videoDetailsFirst);
        this.setState({ playButtonClicked: true });
    };

     getTitle(title) {
        return title.replace(/ *\([^)]*\) */g, "");
    };
    render() {
        const { movie, row } = this.props;

        if (this.state.playButtonClicked) {
            const returnToVideo = this.renderRedirectPage(
                this.state.videoDetailsFirst
            );

            if (returnToVideo != null) {
                return returnToVideo;
            }
        }

        return (
            <SliderContext.Consumer>
                {({ onSelectSlide, currentSlide, elementRef }) => {
                    const isActive =
                        currentSlide &&
                        currentSlide.admin_video_id === movie.admin_video_id;

                    return (
                        <div
                            ref={elementRef}
                            className={cx("item item5 sliderthumb-text", {
                                "item--open": isActive
                            })}
                            style={{
                                backgroundImage:
                                    "url(" + movie.default_image + ")"
                            }}
                            id={row}
                        >
                                               
                            
                            <div className="width-100 slider-content-box">
                           
                                <h4 className="thumb-title">{this.getTitle(movie.title)}</h4>
                              
                                <p className="thumb-desc">
                                    {movie.description}{" "}
                                </p>
                        
                                <div className="container item_container">
                                    <Link to="/subscribe-with-Stripe">
                                    <div className="thumb-playicon">
                                        <i className="fas fa-play" />
                                    </div>
                                </Link>
                                </div>
                                
                            </div>
                            {isActive && <Mark />}
                        </div>
                    );
                }}
            </SliderContext.Consumer>
        );
    }
}

export default Item;